<template>
  <div
    class="file-dropzone"
    @click="onDropZoneClick"
    @dragover.stop.prevent="showDropZone"
    @dragleave.stop.prevent="hideDropZone"
    @drop.stop.prevent="onDrop"
  >
    <slot />
    <v-fade-transition>
      <div v-if="isDropZoneDisplayed || alwaysVisible" class="file-dropzone-overlay">
        <div class="text-center">
          <h2
            class="text-h5 font-weight-medium"
            @dragover.stop.prevent="isDropZoneDisplayed = true"
          >
            {{ $t('file_dropzone_message') }}
          </h2>
        </div>
      </div>
    </v-fade-transition>
    <input :multiple="multiple" type="file" ref="fileInput" class="d-none" @change="onFileSelect" />
  </div>
</template>

<script>
export default {
  name: 'BaseFileDropZone',

  props: {
    alwaysVisible: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDropZoneDisplayed: false,
      timerID: null,
    };
  },

  methods: {
    hideDropZone() {
      clearTimeout(this.timerID);
      // timeout is here to avoid flickering when hovering over helper text inside overlay
      this.timerID = setTimeout(() => {
        this.isDropZoneDisplayed = false;
      }, 100);
    },

    showDropZone() {
      if (this.disabled) {
        return;
      }
      clearTimeout(this.timerID);
      this.isDropZoneDisplayed = true;
    },

    onDrop(evt) {
      // TODO validate files by passed accept prop
      this.isDropZoneDisplayed = false;
      const files = evt.dataTransfer?.files ? evt.dataTransfer.files : [];
      this.$emit('drop', files);
    },

    onDropZoneClick() {
      this.$refs.fileInput.click();
    },

    onFileSelect(evt) {
      this.$emit('drop', evt.target.files);
      // eslint-disable-next-line no-param-reassign
      evt.target.value = '';
    },
  },
};
</script>

<style scoped>
.file-dropzone {
  position: relative;
  display: flex;
  justify-content: center;
}

.file-dropzone-overlay {
  background-color: rgba(255, 255, 255, 0.85);
  border: 5px dashed #1976d2;
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
