<template>
  <div>
    <v-tooltip
      v-if="text && text.length > truncatedLength"
      max-width="500"
      transition="none"
      bottom
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          {{ text | truncate(truncatedLength) }}
        </span>
      </template>
      <span class="preserve-whitespace">
        {{ text }}
      </span>
    </v-tooltip>
    <span v-else>
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseTruncatedField',

  props: {
    text: {
      type: String,
      default: '',
    },

    truncatedLength: {
      type: Number,
      default: 80,
    },
  },
};
</script>
