<template>
  <v-form @submit.prevent="$emit('save', importedExportedItem)" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col v-if="isExport" cols="12">
            <v-text-field
              v-model="importedExportedItem.email"
              :error-messages="errors['email']"
              :label="formMixin_getRequiredFieldLabel($t('email_for_export_data'))"
              @input="formMixin_clearErrors('email')"
            />
          </v-col>
          <v-col v-else cols="12">
            <p class="mx-2 mb-0 high-line-text-bottom">
              {{ formMixin_getRequiredFieldLabel($t('orders_file_for_import')) }}
            </p>
            <BaseFileDropZone
              @drop="
                onFileSelect($event);
                formMixin_clearErrors('file');
              "
              class="ma-2"
            >
              <div class="drop-zone">
                <v-icon v-if="!importedExportedItem.file" x-large class="mx-3"
                  >mdi-plus-circle</v-icon
                >

                <div v-else class="file-icon-width">
                  <div class="file-icon">
                    <v-icon x-large>mdi-file</v-icon>
                    <v-icon small class="close-icon" @click.stop="removeFile">mdi-close</v-icon>
                    <BaseTruncatedField
                      :text="importedExportedItem.file.name"
                      :truncatedLength="8"
                    />
                  </div>
                </div>
              </div>
            </BaseFileDropZone>

            <div
              v-if="errors['file']"
              class="v-messages error--text mb-2 mx-2"
              :class="$isExplorer() ? 'mt-0' : 'mt-3 '"
            >
              <div v-for="(error, index) in errors['file']" :key="index">
                {{ error }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t(isExport ? 'export' : 'import') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';

export default {
  name: 'ImportExportForm',
  components: { BaseTruncatedField, BaseFileDropZone },
  mixins: [formMixin],

  props: {
    importedExportedItem: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    isExport() {
      return this.$route.name === 'exportOrders';
    },

    formTitle() {
      return this.$t(this.isExport ? 'export_orders' : 'import_orders');
    },
  },

  methods: {
    onFileSelect(files) {
      this.$set(this.importedExportedItem, 'file', files[0]);
    },

    removeFile() {
      this.$set(this.importedExportedItem, 'file', null);
    },
  },
};
</script>

<style scoped></style>
